var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"supplier","bladeName":"suppliers","bladesData":_vm.bladesData,"canSearchLocal":"","headers":[
        { text: 'Company Name', value: 'seller.companyName', title: 1, csv: true, searchable: true },
        { text: 'Can Order', value: 'isCustomerOrderingEnabled', bool: true },
        { text: 'Ordering Arrangement', value: 'orderingArrangement', hide: false }],"itemProperties":['ID', 'SellerID', 'Seller', 'OrderingArrangement', 'IsCustomerOrderingEnabled'],"navigation":"suppliers","syncNavigation":"supplier-syncing","title":"Suppliers","useServerPagination":"","defaultBladeWidth":350},scopedSlots:_vm._u([{key:"toolbar-right-far",fn:function(ref){
        var compSmall = ref.compSmall;
return [_c('BT-Menu',{attrs:{"hideHeader":"","icon":"mdi-plus","small":compSmall}},[_c('v-list-item',{attrs:{"to":{ name: 'supplier', params: { id: 'new' } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Create Supplier")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'new-suppliers' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Create Muliple Suppliers")])],1)],1)],1)]}},{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{staticClass:"my-auto",attrs:{"src":_vm.companyLogoURL(item.sellerID)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{staticClass:"primary--text",attrs:{"size":"40"}},[_vm._v("mdi-factory")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.seller.companyName))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }