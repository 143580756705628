<template>
    <BT-Blade-Items
        addBladeName="supplier"
        bladeName="suppliers"
        :bladesData="bladesData"
        canSearchLocal
        :headers="[
            { text: 'Company Name', value: 'seller.companyName', title: 1, csv: true, searchable: true },
            { text: 'Can Order', value: 'isCustomerOrderingEnabled', bool: true },
            { text: 'Ordering Arrangement', value: 'orderingArrangement', hide: false }]"
        :itemProperties="['ID', 'SellerID', 'Seller', 'OrderingArrangement', 'IsCustomerOrderingEnabled']"
        navigation="suppliers"
        syncNavigation="supplier-syncing"
        title="Suppliers"
        useServerPagination
        :defaultBladeWidth="350">
        <template v-slot:toolbar-right-far="{ compSmall }">
            <BT-Menu
                
                hideHeader
                icon="mdi-plus"
                :small="compSmall">
                <!-- <v-list-item :to="{ name: 'public-suppliers' }">
                    <v-list-item-icon>
                        <v-icon>mdi-store-search</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Find Existing</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider /> -->
                <v-list-item :to="{ name: 'supplier', params: { id: 'new' } }">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Create Supplier</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'new-suppliers' }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Create Muliple Suppliers</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </BT-Menu>
            <!-- <v-btn small text :to="{ name: 'public-suppliers' }">
                <v-icon left small>mdi-store-search</v-icon>Find
            </v-btn> -->
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-avatar>
                <v-img :src="companyLogoURL(item.sellerID)" class="my-auto">
                    <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-factory</v-icon></template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
            </v-list-item-content>
        </template>
        <!-- <template slot="settings">
            <v-list-item :to="{ name: 'supplier-requests' }">
                <v-list-item-icon>
                    <v-icon small>mdi-account-question</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-subtitle>Sent Requests</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template> -->
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Suppliers-Blade',
    components: {
        // BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        // BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        // BTSidebarList: () => import('~components/BT-Sidebar-List.vue')
    },
    data: function() {
        return {
            // showGroups: false,
            // showJourneys: false,
            // showLive: false,
            // showSlots: false,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    }
}
</script>